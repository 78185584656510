<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">

        <div class="contacts-page"> 

            <BHLoading :show="loadingPage" />
            <h4 class="mb-3">{{title}}</h4>

            <div class="contacts-page h-full w-full mt-4">
                <CategoryTable @addNewCategory="addNewCategory" @deleteCategory="deleteCategory" />
            </div>
        </div>

    </div>
</template>

<script>
    import {getBase64,arrToObj,formatDate} from 'bh-mod'
    import BHLoading from 'bh-mod/components/common/Loading'
    import CategoryTable from '@/components/features/CategoryTable'
    export default {
        components:{
            BHLoading,CategoryTable
        },
        data() {
            return {
                title:'Category Settings',

                duplicate:{
                    name:'',
                    id:'',
                    show:false,
                    load:false
                },
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1'
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            origFeatures(){
                console.log('ORIGINAL FEATURES', Object.values(this.$store.state.features.features))
                return Object.values(this.$store.state.features.features)
            },
            groups(){
                console.log('GROUPS', Object.values(this.$store.state.features.groups))
                return Object.values(this.$store.state.features.groups)
            },
            currentPage:{
                get(){
                    return this.$store.state.features.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            newStoreTime(){
                return this.$store.state.features.newStoreTime
            },
            loadingPage:{
                get(){
                    return this.$store.state.features.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            addNewCategory(obj){
                console.log('ADD NEW CATEGORY', obj)
            },
            deleteCategory(obj){
                console.log('DELETE CATEGORY', obj)
            },
            selectImg(data) {
                // console.log('data', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newFeature.data.media = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            selectThumbnail(data) {
                // console.log('data', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newFeature.thumbnail = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            formats(e){
                console.log('FORMATS', e)
            },
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts 
                return (delivered / contacts.length) * 100
            },
            itemMenuClick( { item, key, keyPath },broadcast) {
                console.log('BROADCAST CLICKED',broadcast,key)
                if (key === 'dup'){
                    this.dupTemplate(broadcast.email)
                } else if (key === 'report'){
                    this.$message.error('This functionality is currently not available')
                }
            },
            async handleDuplicate(){

                this.duplicate.load = true 

                let {id,name} = this.duplicate
                let {data} = await this.$api.post(`/emails/${this.instance.id}/clone`,{id,name})
                
                this.$store.commit('SHOW_EDIT_TEMPLATE',data)
                setTimeout(() => {
                    this.duplicate.load = false
                    this.closeDuplicate()
                }, 1000);

                // console.log('COPIED TEMPLATE CLONEDD******* ',data)
            },
            openTemplate(template){
                console.log('TEMPLATE')
                this.$store.commit('SHOW_EDIT_TEMPLATE',template)
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
           
        },
        mounted(){

        },
        created() {
            console.log('sdafklsadjflkasdjl')
            
            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
            
        }

    }
</script>

<style lang="scss" scoped>
    .image-holder-box{
        display: table;
        width: 100%;
        height: 203px;
        vertical-align: top; 
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
    }
    .image-holder-box:hover{
        border-color: var(--orange);
        transition: border-color .3s ease;
    }
    .image-holder-box .content{
        display: table-cell;
        width: 100%;
        height: 100%;
        padding: 8px;
        text-align: center;
        vertical-align: middle;
    }
    .image-holder-box .content .inside{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 100%;
        height: 203px;
    }
    .image-selector {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height:100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    @media screen and (min-width: 1250px){
        .responsiveFlex {
            display: flex;
            gap: 1.25em;
        }
        .leftSide {
            width: 75%;
        }
        .rightSide {
            width: 25%;
        }
    }
    @media screen and (max-width: 1250px){
        .responsiveFlex {
            display: block;
        }
        .leftSide {
            width: 100%;
        }
        .rightSide {
            width: 100%;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:2%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }
    
    
        
</style>

