<template>
    <div style="background-color: white;">
        <a-modal :visible="showGroupModal" centered @ok="makeNewGroup" @cancel="closeNewGroup" :title="type == 'add' ? 'Add New Category' : 'Edit Category'" :confirmLoading="newGroupModalProcessing" :okText="type == 'add' ? 'Create Category' : 'Update Category'">
            <a-form>
                <a-form-item label="Enter category name">
                    <a-input v-model="newGroupName" />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="groups" :pagination="false">
            <div slot="name" slot-scope="obj" class="f1 dF aC categoryName">
                {{obj.name}}
                <svg @click="makeEditGroup(obj)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--orange)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 editIcon" style="cursor: pointer;"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
            </div>
            <div slot="action" slot-scope="obj" class="dF jE">
                <i v-if="$p > 10" @click="deleteCategory(obj)" class="fe fe-trash-2 ml-3 actionIcons" style="font-size: 16px; "/>
            </div>
        </a-table>
        <div @click="showGroupModal = true" style="display: inline-block; color:var(--orange); cursor:pointer; padding: 16px;">
            <div class="dF aC">
                <a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
                <span>Add Category</span>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    props:{

    },
    computed:{
        groups(){
            console.log('GROUPS', Object.values(this.$store.state.features.groups))
            return Object.values(this.$store.state.features.groups)
        },
    },
    watch:{

    },
    data(){
        return{
            showGroupModal:false,
            newGroupModalProcessing:false,
            newGroupName:'',
            type:'add',

            editGroup:{},

            columns:[
                {
                    title: 'Category Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },

                {
                    title: 'Actions',
                    key: 'action',
                    align: 'right',
                    width: 100,
                    scopedSlots: { customRender: 'action' },
                },
            ],
        }
    },
    methods:{
        deleteCategory(obj){
            console.log('DELETING CATEGORY', obj)
            let self = this
            this.$confirm({
                title: 'Delete Category',
                content: h => <div>Do you want to delete <b>{obj.name}</b>?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    console.log('DELETING CATEGORY', obj)
                    self.deleteGroup(obj.id)
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        deleteGroup(id){
            console.log('GROUP ID', id)
            this.$api.delete(`/feature-groups/:instance/${id}`).then( ({data}) => {
                console.log('DATA FROM API ', data)
                this.$store.commit('SET_PROP',{where:['groups', data.id], what:data, del:true})
            }).catch(err => {
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))
            })
        },
        makeEditGroup(group){
            this.type = 'edit'
            this.showGroupModal = true
            this.newGroupName = group.name
            this.editGroup = group
        },
        closeNewGroup(){
            this.newGroupName = ''
            this.type = 'add'
            this.editGroup = {}
            this.showGroupModal = false
            this.newGroupModalProcessing = false
        },
        async makeNewGroup(){
            if (!this.newGroupName.trim()) return this.$message.error('Name cannot be blank.')
            this.newGroupModalProcessing = true
            if (this.type == 'add'){
                console.log('NEW ', this.newGroupName)

                this.addGroup(this.newGroupName).then( () => {
                    console.log('DONEEEE')
                    this.newGroupModalProcessing = false
                    this.closeNewGroup()
                })
            } else {
                console.log('EDIT ', this.editGroup)

                if (this.editGroup.name !== this.newGroupName.trim()){
                    let {data} = await this.$api.put(`/feature-groups/${this.$store.state.instance.id}/${this.editGroup.id}`, {name:this.newGroupName.trim()})
                    if (data.id) this.$message.success('Successfully Updated'), this.$store.commit('SET_PROP',{where:['groups', data.id], what:data}), this.closeNewGroup()
                } else {
                    this.closeNewGroup()
                }
            }

        },
        addGroup(name = ''){
            return new Promise( (resolve,reject) => {

                if (!name) return this.$message.error('Name cannot be blank.'),reject()

                this.newGroupModalProcessing = true

                this.$api.post(`/feature-groups/${this.$store.state.instance.id}`, {
                    name
                }).then( ({data}) => {
                    this.newGroupModalProcessing = false

                    this.$store.commit('SET_PROP',{where:['groups', data.id], what:data})
                    this.$message.success('Successfully added Category')
                    resolve()
                    console.log('DATA FROM API ', data)
                }).catch(err => {
                    this.newGroupModalProcessing = false
                    console.error('ERRR', this.$err(err))
                    this.$message.error(this.$err(err))
                    reject(this.$err(err))
                })
            })

        },
    }
}
</script>

<style lang="scss" scoped>
    .categoryName:hover {
        .editIcon {
            display: block;
        }
    }
    .editIcon {
        display: none;
    }
    .actionIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .actionIcons:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
    .editIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcons:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
</style>
